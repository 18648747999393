// Used to store any kind of global settings that are useful at build time and don't need to be dynamic within the React app.

const settings = {
  d7_domain:
    process.env.REACT_APP_D7_DOMAIN ??
    "https://d7.stg.timeshighereducation.com",
  academic_domain:
    process.env.REACT_APP_ACADEMIC_DOMAIN ??
    "https://academic-ui.stg.timeshighereducation.com",
  student_domain:
    process.env.REACT_APP_STUDENT_DOMAIN ??
    "https://student-ui.stg.timeshighereducation.com",
  campus_domain:
    process.env.REACT_APP_CAMPUS_DOMAIN ??
    "https://resources-ui.stg.timeshighereducation.com",
};

export default settings;
