import settings from "./settings";
const d7_domain = settings.d7_domain;
const student_domain = settings.student_domain;
const campus_domain = settings.campus_domain;

export const secondaryData = {
  en: [
    {
      parent: `news`,
      label: `News`,
      href: `${d7_domain}/academic/news`,
      searchHref: `${d7_domain}/search`,
      menu: [
        {
          id: `news:home`,
          label: `Home`,
          href: `${d7_domain}/academic/news`,
        },
        {
          id: `news:latest`,
          label: `Latest`,
          href: `${d7_domain}/academic/news/all`,
        },
        {
          id: `news:opinion`,
          label: `Opinion`,
          href: `${d7_domain}/academic/opinion`,
        },
        {
          id: `news:features`,
          label: `In-depth`,
          href: `${d7_domain}/academic/features`,
        },
        {
          id: `news:leadership`,
          label: `Leadership`,
          href: `${d7_domain}/academic/talking-leadership`,
        },
        {
          id: `news:editions`,
          label: `Digital editions`,
          href: `${d7_domain}/digital-editions`,
        },
        {
          id: `news:newsletters`,
          label: `Newsletters`,
          href: `${d7_domain}/newsletters`,
        },
      ],
    },
    {
      parent: `resources`,
      label: `Campus`,
      href: `${campus_domain}/campus`,
      searchHref: null,
      tagline: `Resources for <br />academics and university staff`,
      menu: [
        {
          id: `resources:home`,
          label: `Home`,
          href: `${campus_domain}/campus`,
        },
        {
          id: `resources:topics`,
          label: `Key topics`,
          href: `${campus_domain}/campus/key-topics`,
        },
        {
          id: `resources:spotlights`,
          label: `Spotlights`,
          href: `${campus_domain}/campus/spotlight`,
        },
        {
          id: `resources:collections`,
          label: `Collections`,
          href: `${campus_domain}/campus/collections`,
        },
        {
          id: `resources:podcast`,
          label: `Podcasts`,
          href: `${campus_domain}/campus/keywords/podcast`,
        },
        {
          id: `resources:partners`,
          label: `Partners`,
          href: `${campus_domain}/campus/partners`,
        },
        {
          id: `resources:green_skills`,
          label: `Green skills`,
          href: `${campus_domain}/green-skills`,
        },
        {
          id: `resources:about`,
          label: `About`,
          href: `${campus_domain}/campus/about-campus`,
        },
        {
          id: `resources:newsletters`,
          label: `Newsletters`,
          href: `${campus_domain}/newsletters`,
        },
      ],
    },
    {
      parent: `jobs`,
      label: `Jobs`,
      href: `https://www.timeshighereducation.com/unijobs`,
      searchHref: null,
      menu: [
        {
          id: `jobs:home`,
          label: `Home`,
          href: `https://www.timeshighereducation.com/unijobs`,
        },
        {
          id: `jobs:find`,
          label: `Find a job`,
          href: `https://www.timeshighereducation.com/unijobs/listings`,
        },
        {
          id: `jobs:alerts`,
          label: `Jobs alerts`,
          href: `https://www.timeshighereducation.com/unijobs/newalert`,
        },
        {
          id: `jobs:careers`,
          label: `Careers advice`,
          href: `https://www.timeshighereducation.com/unijobs/careers`,
        },
        {
          id: `jobs:post`,
          label: `Post a job`,
          href: `https://recruiters.timeshighereducation.com/pricing`,
        },
      ],
    },
    {
      parent: `events`,
      label: `Events`,
      href: `${d7_domain}/events`,
      searchHref: `${d7_domain}/search`,
      menu: [
        {
          id: `events:home`,
          label: `Home`,
          href: `${d7_domain}/events`,
        },
        {
          id: `events:summits`,
          label: `Upcoming events`,
          href: `${d7_domain}/events/upcoming`,
        },
        {
          id: `events:getinvolved`,
          label: `Partner with us`,
          href: `${d7_domain}/events/sponsor`,
        },
        {
          id: `events:awards`,
          label: `Awards`,
          href: `${d7_domain}/events/awards`,
        },
      ],
    },
    {
      parent: `rankings`,
      label: `Rankings`,
      href: `${d7_domain}/world-university-rankings`,
      searchHref: `${d7_domain}/search`,
      menu: [
        {
          id: `rankings:home`,
          label: `Home`,
          href: `${d7_domain}/world-university-rankings`,
        },
        {
          id: `rankings:wur`,
          label: `World University Rankings`,
          href: `${d7_domain}/world-university-rankings/2024/world-ranking`,
        },
        {
          id: `rankings:impact`,
          label: `Impact Rankings`,
          href: `${d7_domain}/impactrankings`,
        },
        {
          id: `rankings:subject`,
          label: `By subject`,
          href: `${d7_domain}/world-university-rankings/by-subject`,
        },
        {
          id: `rankings:reputation`,
          label: `Reputation Rankings`,
          href: `${d7_domain}/world-university-rankings/2023/reputation-ranking`,
        },
        {
          id: `rankings:arabranking`,
          label: `Arab Rankings`,
          href: `${d7_domain}/world-university-rankings/2023/arab-university-rankings`,
        },
        {
          id: `rankings:asiaranking`,
          label: `Asia Rankings`,
          href: `${d7_domain}/world-university-rankings/2024/regional-ranking`,
        },
        {
          id: `rankings:csr`,
          label: `China Subject Ratings`,
          href: `${d7_domain}/ratings/china-subject-ratings/2024`,
        },
        {
          id: `rankings:japan`,
          label: `Japan University Rankings`,
          href: `${d7_domain}/rankings/japan-university/2023`,
        },
        {
          id: `rankings:ssaranking`,
          label: `Sub-Saharan Africa University Rankings`,
          href: `${d7_domain}/sub-saharan-africa-university-rankings`,
        },
        {
          id: `rankings:news`,
          label: `News`,
          href: `${d7_domain}/policy/rankings`,
        },
        {
          id: `rankings:about`,
          label: `About THE rankings`,
          href: `${d7_domain}/world-university-rankings/about-the-times-higher-education-world-university-rankings`,
        },
      ],
    },
    {
      parent: `student`,
      label: `Student`,
      href: `${student_domain}/student`,
      searchHref: null,
      ambassadorsHref: `${student_domain}/student/ambassadors`,
      tagline: `Everything you need for each step <br />of your study abroad journey`,
      menu: [
        {
          id: `student:home`,
          label: `Home`,
          href: `${student_domain}/student`,
        },
        {
          id: `student:best`,
          label: `Best universities`,
          href: `${student_domain}/student/best-universities`,
        },
        {
          id: `student:business_education`,
          label: `Business education`,
          href: `${student_domain}/student/poets-and-quants`,
        },
        {
          id: `student:events`,
          label: `Events/ festivals`,
          href: `${student_domain}/student/events`,
        },
        {
          id: `student:certifications`,
          label: `Certifications`,
          href: `${student_domain}/student/certifications/skills-now-maximise-your-employability`,
        },
        {
          id: `student:counsellor_resources`,
          label: `Counsellor resources`,
          href: `${student_domain}/counsellor`,
        },
      ],
    },
    {
      parent: `solutions`,
      label: `Solutions`,
      href: `${d7_domain}/our-solutions`,
      searchHref: `${d7_domain}/search`,
      menu: [
        {
          id: `solutions:home`,
          label: `Home`,
          href: `${d7_domain}/our-solutions`,
        },
        {
          id: `solutions:data`,
          label: `Data and insights`,
          href: `${d7_domain}/our-solutions/data-and-insights`,
        },
        {
          id: `solutions:consultancy`,
          label: `Consultancy`,
          href: `${d7_domain}/our-solutions/consultancy`,
        },
        {
          id: `solutions:hiring`,
          label: `Hiring solutions`,
          href: `${d7_domain}/our-solutions/hiring-solutions`,
        },
        {
          id: `solutions:branding`,
          label: `Branding`,
          href: `${d7_domain}/our-solutions/branding`,
        },
        {
          id: `solutions:institutional`,
          label: `Institutional subscriptions`,
          href: `${d7_domain}/our-solutions/institutional-subscriptions`,
        },
        {
          id: `solutions:student`,
          label: `Student recruitment`,
          href: `${d7_domain}/our-solutions/student-recruitment`,
        },
        {
          id: `solutions:campusplus`,
          label: `Campus+`,
          href: `${d7_domain}/our-solutions/campus-partnership`,
        },
      ],
    },
  ],
  cn: [
    {
      parent: `news`,
      label: `高教新闻`,
      href: `${d7_domain}/cn/academic/news`,
      searchHref: `${d7_domain}/cn/search`,
      menu: [
        {
          id: `news:home`,
          label: `新闻首页`,
          href: `${d7_domain}/cn/academic/news`,
        },
        {
          id: `news:latest`,
          label: `最新动态`,
          href: `${d7_domain}/cn/academic/news/all`,
        },
        {
          id: `news:opinion`,
          label: `专业意见`,
          href: `${d7_domain}/cn/academic/opinion`,
        },
        {
          id: `news:features`,
          label: `深度特稿`,
          href: `${d7_domain}/cn/academic/features`,
        },
        {
          id: `news:leadership`,
          label: `领导力`,
          href: `${d7_domain}/cn/academic/talking-leadership`,
        },
        {
          id: `news:editions`,
          label: `电子周刊`,
          href: `${d7_domain}/cn/digital-editions`,
        },
        {
          id: `news:newsletters`,
          label: `邮件简报`,
          href: `${d7_domain}/cn/newsletters`,
        },
      ],
    },
    {
      parent: `resources`,
      label: `资源`,
      href: `${campus_domain}/campus`,
      searchHref: null,
      tagline: `Resources for <br />academics and university staff`,
      menu: [
        {
          id: `resources:home`,
          label: `Home`,
          href: `${campus_domain}/campus`,
        },
        {
          id: `resources:topics`,
          label: `议题`,
          href: `${campus_domain}/campus/key-topics`,
        },
        {
          id: `resources:spotlights`,
          label: `Spotlights`,
          href: `${campus_domain}/campus/spotlight`,
        },
        {
          id: `resources:collections`,
          label: `Collections`,
          href: `${campus_domain}/campus/collections`,
        },
        {
          id: `resources:podcast`,
          label: `Podcasts`,
          href: `${campus_domain}/campus/keywords/podcast`,
        },
        {
          id: `resources:partners`,
          label: `Partners`,
          href: `${campus_domain}/campus/partners`,
        },
        {
          id: `resources:green_skills`,
          label: `Green skills`,
          href: `${campus_domain}/green-skills`,
        },
        {
          id: `resources:about`,
          label: `About`,
          href: `${campus_domain}/campus/about-campus`,
        },
        {
          id: `resources:newsletters`,
          label: `Newsletters`,
          href: `${campus_domain}/newsletters`,
        },
      ],
    },
    {
      parent: `jobs`,
      label: `Jobs`,
      href: `https://www.timeshighereducation.com/unijobs`,
      searchHref: null,
      menu: [
        {
          id: `jobs:home`,
          label: `Home`,
          href: `https://www.timeshighereducation.com/unijobs`,
        },
        {
          id: `jobs:find`,
          label: `Find a job`,
          href: `https://www.timeshighereducation.com/unijobs/listings`,
        },
        {
          id: `jobs:alerts`,
          label: `Jobs alerts`,
          href: `https://www.timeshighereducation.com/unijobs/newalert`,
        },
        {
          id: `jobs:careers`,
          label: `Careers advice`,
          href: `https://www.timeshighereducation.com/unijobs/careers`,
        },
        {
          id: `jobs:post`,
          label: `Post a job`,
          href: `https://recruiters.timeshighereducation.com/pricing`,
        },
      ],
    },
    {
      parent: `events`,
      label: `活动`,
      href: `${d7_domain}/cn/events`,
      searchHref: `${d7_domain}/cn/search`,
      menu: [
        {
          id: `events:home`,
          label: `活动首页`,
          href: `${d7_domain}/cn/events`,
        },
        {
          id: `events:summits`,
          label: `活动预告`,
          href: `${d7_domain}/cn/events/upcoming`,
        },
        {
          id: `events:getinvolved`,
          label: `与我们合作`,
          href: `${d7_domain}/cn/events/sponsor`,
        },
        {
          id: `events:awards`,
          label: `大奖`,
          href: `${d7_domain}/cn/events/awards`,
        },
      ],
    },
    {
      parent: `rankings`,
      label: `排名`,
      href: `${d7_domain}/cn/world-university-rankings`,
      searchHref: `${d7_domain}/cn/search`,
      menu: [
        {
          id: `rankings:home`,
          label: `排名首页`,
          href: `${d7_domain}/cn/world-university-rankings`,
        },
        {
          id: `rankings:wur`,
          label: `世界大学排名`,
          href: `${d7_domain}/cn/world-university-rankings/2024/world-ranking`,
        },
        {
          id: `rankings:impact`,
          label: `世界大学影响力排名`,
          href: `${d7_domain}/cn/impactrankings`,
        },
        {
          id: `rankings:subject`,
          label: `学科排名`,
          href: `${d7_domain}/cn/world-university-rankings/by-subject`,
        },
        {
          id: `rankings:reputation`,
          label: `世界大学声誉排名`,
          href: `${d7_domain}/cn/world-university-rankings/2023/reputation-ranking`,
        },
        {
          id: `rankings:arabranking`,
          label: `阿拉伯国家大学排名`,
          href: `${d7_domain}/cn/world-university-rankings/2023/arab-university-rankings`,
        },
        {
          id: `rankings:asiaranking`,
          label: `亚洲国家大学排名`,
          href: `${d7_domain}/cn/world-university-rankings/2024/regional-ranking`,
        },
        {
          id: `rankings:csr`,
          label: `中国学科评级`,
          href: `${d7_domain}/cn/ratings/china-subject-ratings/2024`,
        },
        {
          id: `rankings:japan`,
          label: `日本大学排名`,
          href: `${d7_domain}/cn/rankings/japan-university/2023`,
        },
        {
          id: `rankings:news`,
          label: `新闻快报`,
          href: `${d7_domain}/cn/policy/rankings`,
        },
        {
          id: `rankings:about`,
          label: `关于`,
          href: `${d7_domain}/cn/world-university-rankings/about-the-times-higher-education-world-university-rankings`,
        },
      ],
    },
    {
      parent: `student`,
      label: `Student`,
      href: `${student_domain}/student`,
      searchHref: null,
      ambassadorsHref: `${student_domain}/student/ambassadors`,
      tagline: `Everything you need for each step <br />of your study abroad journey`,
      menu: [
        {
          id: `student:home`,
          label: `Home`,
          href: `${student_domain}/student`,
        },
        {
          id: `student:best`,
          label: `Best Universities`,
          href: `${student_domain}/student/best-universities`,
        },
        {
          id: `student:business_education`,
          label: `Business education`,
          href: `${student_domain}/student/poets-and-quants`,
        },
        {
          id: `student:events`,
          label: `Events/Festivals`,
          href: `${student_domain}/student/events`,
        },
        {
          id: `student:certifications`,
          label: `Certifications`,
          href: `${student_domain}/student/certifications/skills-now-maximise-your-employability`,
        },
        {
          id: `student:counsellor_resources`,
          label: `Counsellor resources`,
          href: `${student_domain}/counsellor`,
        },
      ],
    },
    {
      parent: `solutions`,
      label: `服务`,
      href: `${d7_domain}/cn/our-solutions`,
      searchHref: `${d7_domain}/cn/search`,
      menu: [
        {
          id: `solutions:home`,
          label: `服务首页`,
          href: `${d7_domain}/cn/our-solutions`,
        },
        {
          id: `solutions:data`,
          label: `数据与洞见`,
          href: `${d7_domain}/cn/our-solutions/data-and-insights`,
        },
        {
          id: `solutions:consultancy`,
          label: `战略咨询`,
          href: `${d7_domain}/cn/our-solutions/consultancy`,
        },
        {
          id: `solutions:hiring`,
          label: `人才招聘方案`,
          href: `${d7_domain}/cn/our-solutions/hiring-solutions`,
        },
        {
          id: `solutions:branding`,
          label: `品牌推广`,
          href: `${d7_domain}/cn/our-solutions/branding`,
        },
        {
          id: `solutions:institutional`,
          label: `机构订阅`,
          href: `${d7_domain}/cn/our-solutions/institutional-subscriptions`,
        },
        {
          id: `solutions:student`,
          label: `招生服务`,
          href: `${d7_domain}/cn/our-solutions/student-recruitment`,
        },
        {
          id: `solutions:campusplus`,
          label: `THE校园会员`,
          href: `${d7_domain}/cn/our-solutions/campus-partnership`,
        },
      ],
    },
  ],
};
